import React from "react"
import { RichText } from "jam-cms"
import { GatsbyImage } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Button from "../button/Button"

const Cards = (props) => {
  const { columns, items, bgcolor } = props

  return (
    <div className={`bg-${(bgcolor && bgcolor) || "gray-600"}`}>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className={`grid gap-8 lg:grid-cols-${columns} sm:max-w-sm sm:mx-auto lg:max-w-full`}>
          {items &&
            items.map((o, idx) => (
              <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm" key={idx}>
                {o.image ? (
                  <GatsbyImage image={o.image} alt={o.image.altText} className="object-cover w-full h-64" />
                ) : (
                  <img
                    className="object-cover w-full h-64"
                    src="https://images.pexels.com/photos/3182796/pexels-photo-3182796.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                    alt=""
                  />
                )}
                <div className="p-5 border-t-0">
                  {o.headline && (
                    <a
                      href="/"
                      aria-label="Category"
                      title="Visit the East"
                      className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
                    >
                      {Parser(o.headline)}
                    </a>
                  )}
                  {o.text && <p className="mb-2 text-gray-700">{Parser(o.text)} </p>}
                  {o.buttons && o.buttons.length > 0 && (
                    <>
                      {o.buttons &&
                        o.buttons.map((b, i) => (
                          <Button
                            className={
                              b.variant === "filled"
                                ? "inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                                : "inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700"
                            }
                            key={i}
                            {...b.button}
                            variant={b.variant}
                          />
                        ))}
                    </>
                  )}
                  {o.link && (
                    <Button
                      className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                      {...o.link}
                      variant="text"
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Cards
