import React from "react"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { GatsbyImage, RichText } from "jam-cms"
import { Box } from "theme-ui"

// import app components
import Github from "../../icons/github.svg"

const Footer = (props) => {
  const { text, email, phone, address, title, footermenu, headline, image } = props

  return (
    <>
      <div className={"bg-gray-900 "}>
        <div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8  ">
          <div className="grid align-middle gap-10 row-gap-6  sm:grid-cols-2 lg:grid-cols-3 ">
            <div className="sm:col-span-2 align-middle lg:text-right my-auto  ">
              <a href="/" aria-label="Go home" title="Company" className="inline-flex items-center">
                {image && (
                  <Box>
                    {image?.svg ? (
                      Parser(image.svg)
                    ) : (
                      <GatsbyImage
                        image={image}
                        alt={image?.altText}
                        objectFit="contain"
                        objectPosition="0"
                        className="w-22 h-10 text-white"
                      />
                    )}
                  </Box>
                )}
              </a>
            </div>
            <div className="space-y-2  text-sm">
              {headline && <p className="text-base font-bold tracking-wide text-white">{Parser(headline)}</p>}

              {phone && (
                <div className="flex">
                  <p className="mr-1 text-white">Phone:</p>
                  <a
                    href={`tel:${phone}`}
                    aria-label="Our phone"
                    title="Our phone"
                    className="transition-colors duration-300 text-white hover:underline"
                  >
                    {phone}
                  </a>
                </div>
              )}
              {email && (
                <div className="flex">
                  <p className="mr-1 text-white">Email:</p>
                  <a
                    href={`mailto:${email}`}
                    aria-label="Our email"
                    title="Our email"
                    className="transition-colors duration-300 text-white hover:underline"
                  >
                    {email}
                  </a>
                </div>
              )}
              {address && (
                <div className="flex">
                  <p className="mr-1 text-white">Address:</p>
                  <a
                    href="https://www.google.com/maps"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Our address"
                    title="Our address"
                    className="transition-colors duration-300 text-white hover:underline"
                  >
                    <RichText>{address}</RichText>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex bg-white align-middle py-4 justify-end  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mr-3">
        <p className="text-sm w-full lg:text-right   text-gray-600">
          © Copyright {new Date().getFullYear()}. All rights reserved.
        </p>
        {/* <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
      {footermenu &&
        footermenu.map((o, i) => {
          return (
            <li key={i}>
              <Link to={o.url} className="text-sm text-gray-600 transition-colors duration-300 hover:text-white">
                {o.title}
              </Link>
            </li>
          )
        })}
    </ul>
    */}
      </div>
    </>
  )
}

export default Footer
