const config = {
  id: "imageboxes",
  label: "Image Boxes",
  fields: [
    {
      id: "bgcolor",
      type: "radio",
      label: "Background Color",
      defaultValue: "background-1",
      options: [
        {
          name: "Background 1",
          value: "background-one"
        },
        {
          name: "Background 2",
          value: "background-two"
        },
        {
          name: "Background 3",
          value: "background-three"
        }
      ]
    },
    {
      id: "image1",
      type: "image",
      label: "Add Image 1"
    },
    {
      id: "image2",
      type: "image",
      label: "Add Image 2"
    },
    {
      id: "image3",
      type: "image",
      label: "Add Image 3"
    },
    {
      id: "text",
      type: "wysiwyg",
      label: "Text"
    },
    {
      id: "headline",
      type: "text",
      label: "Headline"
    },
    {
      id: "link",
      type: "link",
      label: "Link"
    },
    {
      id: "alignment",
      type: "select",
      label: "Image Alignment",
      defaultValue: "left",
      options: [
        {
          name: "Left",
          value: "left"
        },
        {
          name: "Right",
          value: "right"
        }
      ]
    }
  ]
}

export default config
