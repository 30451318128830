import buttonFields from "../button/config"

const config = {
  id: "cards",
  label: "Cards",
  fields: [
    {
      id: "bgcolor",
      type: "radio",
      label: "Background Color",
      defaultValue: "background-one",
      options: [
        {
          name: "Background 1",
          value: "background-one"
        },
        {
          name: "Background 2",
          value: "background-two"
        },
        {
          name: "Background 3",
          value: "background-three"
        }
      ]
    },
    {
      id: "columns",
      type: "number",
      label: "Columns",
      defaultValue: 3,
      min: 1,
      max: 4,
      step: 1
    },

    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "headline",
          type: "text",
          label: "Headline"
        },
        {
          id: "image",
          type: "image",
          label: "Logo"
        },
        {
          id: "text",
          type: "text",
          label: "Text",
          rows: 3
        },
        {
          id: "buttons",
          type: "repeater",
          label: "Button",
          items: buttonFields
        }
      ]
    }
  ]
}

export default config
