import React from "react"
import { GatsbyImage, RichText } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Button from "../button/Button"

const ImageBoxes = (props) => {
  const { bgcolor, image1, image2, image3, headline, text, link, alignment } = props

  return (
    <div className={`bg-${(bgcolor && bgcolor) || "gray-600"}`}>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg order-2">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-secondary">
              <svg className="text-secondary-contrast w-7 h-7" viewBox="0 0 24 24">
                <polyline
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points=" 8,5 8,1 16,1 16,5"
                  strokeLinejoin="round"
                />
                <polyline
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points="9,15 1,15 1,5 23,5 23,15 15,15"
                  strokeLinejoin="round"
                />
                <polyline
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points="22,18 22,23 2,23 2,18"
                  strokeLinejoin="round"
                />
                <rect
                  x="9"
                  y="13"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  width="6"
                  height="4"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="max-w-xl mb-6">
              {headline && (
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                  {/*	Let us handle
							<br className="hidden md:block" />
							your next{" "}
							<span className="inline-block text-deep-purple-accent-400">
								destination
            </span>*/}
                  {Parser(headline)}
                </h2>
              )}
              {text && <p className="text-base text-gray-700 md:text-lg">{Parser(text)}</p>}
            </div>
            <div>
              {link && (
                <Button
                  {...link}
                  variant="text"
                  className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-primary-accent-400 hover:text-deep-primary-800"
                />
              )}
              <svg
                className="inline-block w-3 ml-2 transition-colors duration-200 text-primary hover:text-deep-primary-800"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
              </svg>
            </div>
          </div>
          <div className={`order-${alignment === "left" ? 1 : 2}`}>
            <div className="flex items-center justify-center -mx-4 lg:pl-8">
              <div className="flex flex-col items-end px-3">
                {image1 ? (
                  <GatsbyImage
                    image={image1}
                    alt={image1.altText}
                    className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                  />
                ) : (
                  <img
                    className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                    src="https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                    alt=""
                  />
                )}

                {image2 ? (
                  <GatsbyImage
                    image={image2}
                    alt={image2.altText}
                    className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                  />
                ) : (
                  <img
                    className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                    src="https://images.pexels.com/photos/3182812/pexels-photo-3182812.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                    alt=""
                  />
                )}
              </div>
              <div className="px-3">
                {image3 ? (
                  <GatsbyImage
                    image={image3}
                    alt={image3.altText}
                    className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                  />
                ) : (
                  <img
                    className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                    src="https://images.pexels.com/photos/3182739/pexels-photo-3182739.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageBoxes
