import React from "react"
import Parser from "html-react-parser"
import { GatsbyImage, RichText } from "jam-cms"

// import app components
import Button from "../button/Button"

const Postcards = (props) => {
  const { items, bgcolor } = props
  return (
    <div className={`bg-${(bgcolor && bgcolor) || "gray-600"}`}>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid max-w-screen-lg gap-8 row-gap-5 md:row-gap-8 sm:mx-auto lg:grid-cols-2">
          {items &&
            items.map((o, idx) => (
              <div
                key={idx}
                className="transition shadow-xl duration-300 transform bg-white rounded shadow-sm hover:opacity-80 hover:shadow md:text-center"
              >
                <div className="relative">
                  {o.image ? (
                    <GatsbyImage
                      image={o.image}
                      alt={o.image.altText}
                      className="object-cover w-full h-64 rounded-t lg:h-80 xl:h-96"
                    />
                  ) : (
                    <img
                      className="object-cover w-full h-64 rounded-t lg:h-80 xl:h-96"
                      src="https://images.pexels.com/photos/3182796/pexels-photo-3182796.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                      alt=""
                    />
                  )}
                </div>
                <div className="px-6 py-8 border border-t-0 rounded-b sm:px-8">
                  {o.headline && (
                    <h5 className="mb-2 text-blue-b-text text-xl font-bold leading-none sm:text-2xl">
                      {Parser(o.headline)}
                    </h5>
                  )}
                  {o.text && <p className="mb-5 text-blue-b-text">{Parser(o.text)}</p>}
                  {o.buttons && o.buttons.length > 0 && (
                    <>
                      {o.buttons.map((obj, i) => (
                        <Button
                          className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                          key={i}
                          {...obj.button}
                          variant={obj.variant}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Postcards
