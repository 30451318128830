import React from "react"
import { graphql } from "gatsby"

// import app components
import Layout from "../../../../components/Layout"
import hero from "../../../../components/hero/Hero"
import cards from "../../../../components/cards/Cards"
import imageboxes from "../../../../components/imageBoxes/ImageBoxes"
import texteditor from "../../../../components/textEditor/TextEditor"
import posts from "../../../../components/posts/Posts"
import postcards from "../../../../components/postcards/Postcards"
import quote from "../../../../components/quote/Quote"
import angledimage from "../../../../components/angledImage/AngledImage"
import contactus from "../../../../components/contactUs/ContactUs"
import textimage from "../../../../components/textImage/TextImage"

const blocks = {
  hero,
  cards,
  imageboxes,
  texteditor,
  posts,
  postcards,
  quote,
  angledimage,
  contactus,
  textimage
}

const Template = (props) => {
  const {
    pageContext: { themeOptions },
    data: {
      wpPage: {
        template: { acf },
        seo
      }
    }
  } = props

  return (
    <Layout {...props} seo={seo}>
      {acf?.blocks?.flex &&
        acf.blocks.flex.map(({ id: fieldId, fieldGroupName, ...rest }, index) => {
          const id = fieldId || fieldGroupName?.split("_").pop().toLowerCase()

          const Component = blocks?.[id]
          return Component && <Component key={index} {...rest} themeOptions={themeOptions} />
        })}
    </Layout>
  )
}

export const Query = graphql`
  query PageDefault($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      databaseId
      title
      template {
        templateName
      }
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      template {
        ... on WpDefaultTemplate {
          templateName
          acf {
            blocks {
              flex {
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Hero {
                  fieldGroupName
                  buttons {
                    button {
                      target
                      title
                      url
                    }
                    variant
                  }
                  headline
                  image {
                    id
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1920, placeholder: BLURRED)
                      }
                    }
                  }
                  subline
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Cards {
                  columns
                  bgcolor
                  items {
                    headline
                    fieldGroupName
                    text
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 800, placeholder: BLURRED)
                        }
                      }
                    }
                    buttons {
                      button {
                        target
                        title
                        url
                      }
                      variant
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Quote {
                  fieldGroupName
                  text
                  headline
                  bgcolor
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 600, placeholder: BLURRED)
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Postcards {
                  fieldGroupName
                  bgcolor
                  items {
                    headline
                    buttons {
                      button {
                        target
                        title
                        url
                      }
                      variant
                    }
                    text
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 800, placeholder: BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Textimage {
                  fieldGroupName
                  alignment
                  buttons {
                    button {
                      target
                      title
                      url
                    }
                    variant
                    fieldGroupName
                  }
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 800, placeholder: BLURRED)
                      }
                    }
                  }
                  text
                }

                ... on WpDefaultTemplate_Acf_Blocks_Flex_Angledimage {
                  fieldGroupName
                  headline
                  alignment
                  bgcolor
                  text
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 800, placeholder: BLURRED)
                      }
                    }
                  }
                  buttons {
                    button {
                      target
                      title
                      url
                    }
                    variant
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Contactus {
                  fieldGroupName
                  headline
                  bgcolor
                  text
                  phone
                  address
                  email
                  subline
                  formid
                  link {
                    target
                    title
                    url
                  }
                }

                ... on WpDefaultTemplate_Acf_Blocks_Flex_Imageboxes {
                  fieldGroupName
                  alignment
                  bgcolor
                  image1 {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 800, placeholder: BLURRED)
                      }
                    }
                  }
                  image2 {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 800, placeholder: BLURRED)
                      }
                    }
                  }
                  image3 {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 800, placeholder: BLURRED)
                      }
                    }
                  }
                  text
                  headline
                  link {
                    target
                    title
                    url
                  }
                }
                ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor {
                  fieldGroupName
                  flex {
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Text {
                      fieldGroupName
                      text
                    }
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Textimage {
                      alignment
                      fieldGroupName
                      image {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 600, placeholder: BLURRED)
                          }
                        }
                      }
                      text
                    }
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Gallery {
                      columns
                      fieldGroupName
                      gallery {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 600, placeholder: BLURRED)
                          }
                        }
                      }
                    }
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Embed {
                      fieldGroupName
                      url
                    }
                    ... on WpDefaultTemplate_Acf_Blocks_Flex_Texteditor_Flex_Quote {
                      author
                      fieldGroupName
                      position
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Template
