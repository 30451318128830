import React from "react"
import { GatsbyImage, RichText } from "jam-cms"
import Parser from "html-react-parser"
import { Box } from "theme-ui"
import styled from "@emotion/styled"

// import app components
import Button from "../button/Button"

const AngledImage = (props) => {
  const { image, headline, text, buttons, alignment, bgcolor } = props

  return (
    <div className={`bg-${(bgcolor && bgcolor) || "gray-600"}`}>
      <div className={"grid w-full   lg:grid-cols-2 xl:grid-cols-3"}>
        <ImageContainer alignment={alignment}>
          {image && (
            <GatsbyImage
              image={image}
              alt={image.altText}
              style={{ width: "100%", height: "100%" }}
              className="object-cover object-center rounded"
            />
          )}
        </ImageContainer>
        <TextContainer className="my-auto" alignment={alignment}>
          <div>
            {headline && (
              <h1 className="mb-5 font-sans text-3xl font-bold tracking-tight text-secondary-contrast sm:text-4xl sm:leading-none">
                {Parser(headline)}
              </h1>
            )}

            {text && <p className="pr-5 mb-5 text-base text-secondary-contrast md:text-lg">{Parser(text)}</p>}

            {buttons && buttons.length > 0 && (
              <div className={`flex justify-left ${buttons && buttons.length > 0 ? "mt-8" : "mt-0"}`}>
                {buttons.map((obj, i) => (
                  <Button
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-lg text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    key={i}
                    {...obj.button}
                    variant={obj.variant}
                  />
                ))}
              </div>
            )}
          </div>
        </TextContainer>
      </div>
    </div>
  )
}

const TextContainer = styled.div`
  width: 100%;
  padding: 30px;
  @media (min-width: 1024px) {
    grid-column-start: ${(props) => (props.alignment === "left" ? 1 : 2)};
    grid-row-start: 1;
  }
  @media (min-width: 1280px) {
    grid-column-start: ${(props) => (props.alignment === "left" ? 1 : 3)};
    grid-row-start: 1;
  }
`
const ImageContainer = styled.div`
  width: 100%;
  
  @media (min-width: 1024px) {
    grid-column-start: ${(props) => (props.alignment === "left" ? 2 : 1)};
    grid-column-end: ${(props) => (props.alignment === "left" ? 3 : 2)};
    min-height:480px;
  @media (min-width: 1280px) {
    grid-column-start: ${(props) => (props.alignment === "left" ? 2 : 1)};
    grid-column-end: ${(props) => (props.alignment === "left" ? 4 : 3)};

    grid-row-start: 1;
  }
`
export default AngledImage
