import React from "react"
import { GatsbyImage } from "jam-cms"
import Parser from "html-react-parser"

// import app components
import Button from "../button/Button"

const Quote = (props) => {
  const { text, headline, bgcolor, image } = props

  return (
    <div className={`bg-${(bgcolor && bgcolor) || "gray-600"}`}>
      <div className="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8  ">
        <div className="max-w-screen-sm sm:text-center sm:mx-auto">
          <a href="/" aria-label="View" className="inline-block mb-5 rounded-full sm:mx-auto"></a>
          {headline && (
            <h2 className="mb-4 font-sans text-3xl  tracking-tight text-secondary-contrast sm:text-4xl sm:leading-none">
              {Parser(headline)}
            </h2>
          )}

          {text && <p className="text-base font-normal text-secondary-contrast md:text-lg sm:px-4">{Parser(text)}</p>}

          {/*<hr className="w-full my-8 border-gray-300" /> */}
        </div>
      </div>
      {image && (
        <div className=" flex justify-center px-auto w-full  mx-auto">
          {image?.svg ? Parser(image.svg) : <GatsbyImage image={image} alt={image?.altText} className="" />}
        </div>
      )}
    </div>
  )
}

export default Quote
