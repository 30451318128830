const config = {
  id: "quote",
  label: "Quote",
  fields: [
    {
      id: "bgcolor",
      type: "radio",
      label: "Background Color",
      defaultValue: "background-one",
      options: [
        {
          name: "Background 1",
          value: "background-one"
        },
        {
          name: "Background 2",
          value: "background-two"
        },
        {
          name: "Background 3",
          value: "background-three"
        }
      ]
    },
    {
      id: "image",
      type: "image",
      label: "Logo"
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
      defaultValue: "A quote headline"
    },
    {
      id: "text",
      type: "text",
      label: "Text",
      defaultValue:
        "Viral pour-over ugh narwhal flexitarian raclette woke wayfarers direct trade godard yr kogi gentrify authentic"
    }
  ]
}

export default config
