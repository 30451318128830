const config = {
  id: "posts",
  label: "Posts",
  fields: [
    {
      id: "items",
      type: "repeater",
      label: "Items",
      items: [
        {
          id: "image",
          type: "image",
          label: "Add Image",
        },
        {
          id: "subline",
          type: "text",
          label: "Subline",
        },
      ],
    },
  ],
}

export default config
