import React, { useState } from "react"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { GatsbyImage } from "jam-cms"
import { Box } from "theme-ui"

// import app components
import Button from "../button/Button"

const Header = (props) => {
  const { menu, buttons, bgcolor, image } = props

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <div className={`bg-${(bgcolor && bgcolor) || "gray-600"}`}>
      <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:w-full md:px-8 ">
        <div className="relative flex items-center justify-between">
          <a href="/" aria-label="Company" title="Company" className="inline-flex items-center">
            {image && (
              <Box sx={{ svg: { minWidth: [null, "200px", "300px"], minHeight: ["50px", null, "80px"] } }}>
                {image?.svg ? (
                  Parser(image.svg)
                ) : (
                  <GatsbyImage
                    image={image}
                    alt={image?.altText}
                    objectFit="contain"
                    objectPosition="0"
                    className="w-22 h-10 text-primary-contrast "
                  />
                )}
              </Box>
            )}
          </a>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            {menu &&
              menu.map((o, i) => (
                <>
                  {o.children && o.children.length > 0 ? (
                    <Box sx={{ zIndex: 10, ".dropdown:hover": { ".dropdown-menu": { display: "block" } } }}>
                      <div className="dropdown inline-block relative">
                        <Link
                          href={o.url}
                          className="bg-background-header text-primary-contrast font-semibold py-2  rounded inline-flex items-center"
                        >
                          <span className="mr-1">{Parser(o.title)}</span>
                          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                          </svg>
                        </Link>
                        <ul className="dropdown-menu absolute hidden text-primary-contrast pt-1 w-60 ">
                          {o.children.map((ch, idx) => (
                            <li className="" key={ch.key}>
                              <Link
                                className=" bg-background-header hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                                href={ch.url}
                              >
                                {Parser(ch.title)}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Box>
                  ) : (
                    <li key={i}>
                      <Link
                        to={o.url}
                        aria-label={o.title}
                        title={o.title}
                        className="font-medium tracking-wide text-primary-contrast transition-colors duration-200 hover:text-teal-accent-400"
                      >
                        {Parser(o.title)}
                      </Link>
                    </li>
                  )}
                </>
              ))}
            {buttons && buttons.length > 0 && (
              <li>
                {buttons.map((o, i) => (
                  <Button
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    key={i}
                    {...o.button}
                    variant={o.variant}
                  />
                ))}
              </li>
            )}
          </ul>
          <div className="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                <path fill="currentColor" d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z" />
                <path fill="currentColor" d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z" />
                <path fill="currentColor" d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z" />
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full z-10">
                <div className="p-5  bg-white border rounded shadow-sm">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <a href="/" aria-label="Company" title="Company" className="inline-flex items-center">
                        {image && (
                          <Box>
                            {image?.svg ? (
                              Parser(image.svg)
                            ) : (
                              <GatsbyImage
                                image={image}
                                alt={image?.altText}
                                objectFit="contain"
                                objectPosition="0"
                                className="w-22 h-10 text-primary-contrast "
                              />
                            )}
                          </Box>
                        )}
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4 pl-2">
                      {menu &&
                        menu.map((o, i) => (
                          <>
                            {o.children && o.children.length > 0 ? (
                              <Box sx={{ zIndex: 10, ".dropdown:hover": { ".dropdown-menu": { display: "block" } } }}>
                                <div className="dropdown inline-block relative">
                                  <Link
                                    href={o.url}
                                    className="text-primary-contrast font-semibold py-2  rounded inline-flex items-center"
                                  >
                                    <span className="mr-1">{Parser(o.title)}</span>
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                                    </svg>
                                  </Link>
                                  <ul className="dropdown-menu absolute hidden text-primary-contrast pt-1 w-60 border rounded ">
                                    {o.children.map((ch, idx) => (
                                      <li className="" key={ch.key}>
                                        <Link
                                          className="bg-white hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                                          href={ch.url}
                                        >
                                          {Parser(ch.title)}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </Box>
                            ) : (
                              <li key={i}>
                                <Link
                                  to={o.url}
                                  aria-label={o.title}
                                  title={o.title}
                                  className="font-medium tracking-wide text-primary-contrast transition-colors duration-200 hover:text-teal-accent-400"
                                >
                                  {Parser(o.title)}
                                </Link>
                              </li>
                            )}
                          </>
                        ))}
                      {buttons && buttons.length > 0 && (
                        <li>
                          {buttons.map((o, i) => (
                            <Button
                              className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                              key={i}
                              {...o.button}
                              variant={o.variant}
                            />
                          ))}
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
