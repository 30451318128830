import buttonFields from "../button/config"

const config = {
  id: "angledimage",
  label: "Angled Image",
  fields: [
    {
      id: "bgcolor",
      type: "radio",
      label: "Background Color",
      defaultValue: "background-one",
      options: [
        {
          name: "Background 1",
          value: "background-one"
        },
        {
          name: "Background 2",
          value: "background-two"
        },
        {
          name: "Background 3",
          value: "background-three"
        }
      ]
    },
    {
      id: "image",
      type: "image",
      label: "Add Image"
    },
    {
      id: "headline",
      type: "text",
      label: "Headline",
      defaultValue: "Activated charcoal mustache typewriter copper mug"
    },
    {
      id: "text",
      type: "text",
      label: "Text",
      defaultValue:
        "Viral pour-over ugh narwhal flexitarian raclette woke wayfarers direct trade godard yr kogi gentrify authentic"
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonFields
    },
    {
      id: "alignment",
      type: "select",
      label: "Image Alignment",
      defaultValue: "left",
      options: [
        {
          name: "Left",
          value: "left"
        },
        {
          name: "Right",
          value: "right"
        }
      ]
    }
  ]
}

export default config
