// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-source-jam-cms-src-pages-jam-cms-js": () => import("./../../../node_modules/gatsby-source-jam-cms/src/pages/jam-cms.js" /* webpackChunkName: "component---node-modules-gatsby-source-jam-cms-src-pages-jam-cms-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-post-types-page-default-default-js": () => import("./../../../src/templates/postTypes/page/default/default.js" /* webpackChunkName: "component---src-templates-post-types-page-default-default-js" */),
  "component---src-templates-post-types-post-default-default-js": () => import("./../../../src/templates/postTypes/post/default/default.js" /* webpackChunkName: "component---src-templates-post-types-post-default-default-js" */),
  "component---src-templates-taxonomies-category-single-single-js": () => import("./../../../src/templates/taxonomies/category/single/single.js" /* webpackChunkName: "component---src-templates-taxonomies-category-single-single-js" */)
}

