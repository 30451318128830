const config = [
  {
    id: "button",
    type: "link",
    label: "Button"
  },
  {
    id: "variant",
    type: "select",
    label: "Variant",
    defaultValue: "filled",
    options: [
      {
        name: "Filled",
        value: "filled"
      },
      {
        name: "Text",
        value: "text"
      },
      {
        name: "Border",
        value: "border"
      }
    ]
  }
]

export default config
