import buttonFields from "../button/config"

const config = {
  id: "header",
  label: "Header",
  type: "group",
  hide: true,
  fields: [
    {
      id: "bgcolor",
      type: "radio",
      label: "Background Color",
      defaultValue: "background-one",
      options: [
        {
          name: "Background 1",
          value: "background-one"
        },
        {
          name: "Background 2",
          value: "background-two"
        },
        {
          name: "Background 3",
          value: "background-three"
        }
      ]
    },
    {
      id: "image",
      type: "image",
      label: "Logo"
    },

    {
      id: "menu",
      type: "menu",
      label: "Menu"
    },
    {
      id: "buttons",
      type: "repeater",
      label: "Button",
      items: buttonFields
    }
  ]
}

export default config
